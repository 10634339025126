import { setterAmount, setterDate } from '@/plugins/utils';

export interface RefundModelImpl {
  _id: string;
  refund_platform: string;
  charge_id: string;
  refund_amount: number;
  refund_currency: string;
  refund_country: string;
  refund_description: string;
  payment_registry_id: string;
  user_id: string;
  created_at: Date;
  updated_at: Date;
}

export class RefundModelDTO implements RefundModelImpl {
  _id = '';
  refund_platform = '';
  charge_id = '';
  refund_amount = 0;
  refund_currency = '';
  refund_country = '';
  refund_description = '';
  payment_registry_id = '';
  user_id = '';
  created_at = new Date();
  updated_at = new Date();
}

export default class RefundModel extends RefundModelDTO {
  constructor(dto: RefundModelDTO) {
    super();
    Object.assign(this, dto);
  }

  isRefunded = () => this._id.length > 0;
  getRefundAmount = () => `${this.generatePriceFormat((this.refund_amount / 100).toFixed(2))} ${this.refund_currency}`;
  getRefundDate = () => setterDate(this.created_at);

  getExternalLinkRefund() {
    if (this.refund_platform == 'stripe') return `https://dashboard.stripe.com/payments/${this.charge_id}`;
    else if (this.refund_platform == 'paypal')
      return `https://www.sandbox.paypal.com/activity/payment/${this.charge_id}`;
    else return '';
  }

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
