import { setterDate } from '@/plugins/utils';

export interface InvoiceModelImpl {
  _id: string;
  invoice_id_number: number;
  invoice_identification: string;
  invoice_type: string;
  our_company_name: string;
  our_company_street: string;
  our_company_city: string;
  our_company_vat_number: string;
  invoice_date: Date;
  invoice_client: string;
  invoice_client_country: string;
  invoice_client_city: string;
  invoice_client_address: string;
  invoice_client_vat_number: string;
  invoice_product_name: string;
  invoice_price: number;
  invoice_price_tax: number;
  invoice_price_without_tax: number;
  invoice_price_percent_applied_tax: number;
  invoice_price_currency: string;
  invoice_price_currency_sign: string;
  invoice_payment_method: string;
  payment_registry_id: string;
  created_at: Date;
  updated_at: Date;
}

export class InvoiceModelDTO implements InvoiceModelImpl {
  _id = '';
  our_company_name = '';
  our_company_street = '';
  our_company_city = '';
  our_company_vat_number = '';
  invoice_id_number = 0;
  invoice_identification = '';
  invoice_date = new Date();
  invoice_type = '';
  invoice_client = '';
  invoice_client_country = '';
  invoice_client_city = '';
  invoice_client_address = '';
  invoice_client_vat_number = '';
  invoice_product_name = '';
  invoice_price = 0;
  invoice_price_tax = 0;
  invoice_price_without_tax = 0;
  invoice_price_percent_applied_tax = 0;
  invoice_price_currency = '';
  invoice_price_currency_sign = '';
  invoice_payment_method = '';
  payment_registry_id = '';
  created_at = new Date();
  updated_at = new Date();
}

export default class InvoiceModel extends InvoiceModelDTO {
  constructor(dto: InvoiceModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getInvoicePrice = () => this.generatePriceFormat(this.invoice_price.toFixed(2));
  getInvoicePriceTax = () => this.generatePriceFormat(this.invoice_price_tax.toFixed(2));
  getInvoicePriceWithoutTax = () => this.generatePriceFormat(this.invoice_price_without_tax.toFixed(2));
  getInvoiceDate = () => setterDate(this.invoice_date);

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
