import API from '../repository';
import PaymentModel, { PaymentModelDTO } from '@/models/Payment.model';
import CustomerModel, { CustomerModelDTO } from '@/models/Customer.model';
import InvoiceModel, { InvoiceModelDTO } from '@/models/Invoice.model';
import RefundModel, { RefundModelDTO } from '@/models/Refund.model';

const RESOURCE = '/payments';

export default {
  async getListPayments({ query = {}, page = 0, limit = 10 }): Promise<{ payments: PaymentModel[]; total: number }> {
    const { payments, total } = await API.post(`${RESOURCE}`, {
      page,
      limit,
      query,
    });
    const paymentsModels = payments.map((paymentDto: PaymentModelDTO) => new PaymentModel(paymentDto));
    return {
      payments: paymentsModels,
      total: total,
    };
  },

  async getFullDataPayment(
    payment_id: string,
  ): Promise<{
    customer: CustomerModel;
    payment: PaymentModel;
    refund: RefundModel;
    invoices: InvoiceModel[];
  }> {
    const {
      user,
      payment,
      invoices,
      refund,
    }: {
      user: CustomerModelDTO;
      payment: PaymentModelDTO;
      invoices: InvoiceModelDTO[];
      refund: RefundModelDTO;
    } = await API.get(`${RESOURCE}/${payment_id}`);

    const customerModel = new CustomerModel(user);
    const paymentModel = new PaymentModel(payment);
    const invoicesModels = invoices.map(invoice => new InvoiceModel(invoice));
    const refundModel = new RefundModel(refund);

    return {
      customer: customerModel,
      payment: paymentModel,
      refund: refundModel,
      invoices: invoicesModels,
    };
  },

  async createInvoiceForPayment(payment_id: string, forced: boolean): Promise<boolean> {
    return API.post(`${RESOURCE}/${payment_id}/create-invoice`, {
      forced,
    })
      .then(r => Promise.resolve(true))
      .catch(e => Promise.resolve(false));
  },

  async refundPayment(payment_id: string): Promise<boolean> {
    return API.post(`${RESOURCE}/${payment_id}/refund`)
      .then(r => Promise.resolve(true))
      .catch(e => Promise.resolve(false));
  },
};
