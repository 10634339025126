import { setterDate } from '@/plugins/utils';

export interface PaymentModelImpl {
  _id: string;
  product_id: string;
  user_id: string;
  payment_platform: string;
  payment_id: string;
  payment_status: string;
  payment_next_action: string;
  payment_client_secret: string;
  payment_amount: number;
  payment_currency: string;
  payment_description: string;
  payment_country: string;
  error_code: string;
  error_message: string;
  created_at?: Date;
  payment_numeration?: number;
}

export class PaymentModelDTO implements PaymentModelImpl {
  _id = '';
  product_id = '';
  user_id = '';
  payment_platform = '';
  payment_id = '';
  payment_status = '';
  payment_next_action = '';
  payment_client_secret = '';
  payment_amount = 0;
  payment_currency = '';
  payment_description = '';
  payment_country = '';
  error_code = '';
  error_message = '';
  created_at?: Date;
  payment_numeration?: number;
  invoice_id?: string;
  refund_id?: string;
}

export default class PaymentModel extends PaymentModelDTO {
  constructor(dto: PaymentModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getPaymentDate = () => setterDate(this.created_at as Date);

  getExternalLinkPayment() {
    if (this.payment_platform == 'stripe') return `https://dashboard.stripe.com/payments/${this.payment_id}`;
    else if (this.payment_platform == 'paypal')
      return `https://www.sandbox.paypal.com/activity/payment/${this.payment_id}`;
    else return '';
  }

  isSucceeded = () => this.payment_status == 'succeeded';
  getPaymentAmount = () =>
    `${this.generatePriceFormat((this.payment_amount / 100).toFixed(2))} ${this.payment_currency}`;

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
