
















import { Component, Vue } from 'vue-property-decorator';
@Component({
  props: {
    showAlertRefund: {
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ModalRefundPayment extends Vue {}
